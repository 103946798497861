import './About.css';
import { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import vanessa from '../assets/img/van_glitch.png';


const About = () => {
    const [aboutHeight, setAboutHeight] = useState(undefined);

    useEffect(() => {
        const handleResize = () => {
            setAboutHeight(window.innerHeight - document.getElementsByTagName('header')[0].clientHeight - document.getElementsByTagName('footer')[0].clientHeight);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="about-container" style={{height: '100%', minHeight: aboutHeight}}>
            <motion.div
                className="image-container"
                initial={{ y: -100 }}
                whileInView={{ y: 0 }}
                transition={{ type: "spring", delay: 0.2 }}
            >
                <img src={vanessa} alt='vanessa-motion-designer' />
            </motion.div>
            <div className="about-content">
                <motion.p
                    initial={{ x: -100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    Olá! Sou Vanessa, uma profissional apaixonada por arte em movimento.<br/>
                    Sou graduada em Marketing pela Universidade Estácio de Sá, no Rio de Janeiro, e acumulei uma bagagem diversificada atuando em grandes agências do Brasil.
                </motion.p>
                <motion.p
                    initial={{ x: 100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    Atualmente, trabalho como freelancer na criação de animações inteligentes para clientes de diversos setores.<br />
                    A Be Motion é a minha empresa, por meio da qual ofereço serviços especializados em Motion Design e Direção de Arte para o mercado publicitário,
                    criando animações, vinhetas envolventes e dando vida a marcas e logos.
                </motion.p>
                <motion.p
                    initial={{ x: -100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    Acredito que cada projeto tem uma história única para contar, e por isso adoto uma abordagem criativa e personalizada.<br />
                    Sempre que necessário, conto com parceiros de confiança para garantir que cada entrega atenda às necessidades específicas de cada cliente.<br />
                </motion.p>
                <motion.p
                    initial={{ x: 100 }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "spring", delay: 0.2 }}
                >
                    Seja qual for a sua ideia, estou pronta para transformá-la em movimento.<br />
                    Entre em contato, compartilhe sua visão e solicite um orçamento.<br />
                    Vamos dar vida ao seu projeto!
                </motion.p>
            </div>
        </div>
    );
};
export default About;