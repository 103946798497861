import './Header.css';
import logo from '../assets/logos/BeMotion_Baixa.gif';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';


const Header = () => {
    return (
        <header>
            <div className="left-header">
                <ul>
                    <motion.li whileHover={{scale: 1.05}}>
                        <Link to="/" >
                            <img src={logo} alt="logo" />
                        </Link>
                    </motion.li>
                    <li>
                        <Link
                            to='#'
                            onClick={() => window.location = 'mailto:van.bemotion@gmail.com'}
                            className='underline'
                        >
                            van.bemotion@gmail.com
                        </Link>
                    </li>

                </ul>
            </div>
            <nav className="right-header">
                <ul>
                    <li><Link to="https://www.linkedin.com/in/vanbemotion/" target="_blank" rel="noopener noreferrer" className='underline'>linkedin</Link></li>
                    <li><Link to="https://www.instagram.com/_vancarvalho_/" target="_blank" rel="noopener noreferrer" className='underline'>instagram</Link></li>
                    <li><Link to="/about" className='underline'>about</Link></li>
                </ul>
            </nav>
        </header>
    );
};
export default Header;